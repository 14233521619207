import React from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderB,
    PageSection,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { graphql } from 'gatsby';
import { ServiceJumpTable } from '../components/servicejumptable';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { makeSectionId } from '../components/jumpboxlink';
import Seo from '../components/seo';

function SecureEngineering({ data, location }) {
    const { t } = useTranslation();
    const text = [
        {
            title: t(
                'sichere_software_entwicklung_title',
                'Sichere Software Entwicklung'
            ),
            desc: t(
                'sichere_software_entwicklung_desc',
                'Sichere Anwendungen entwickeln und Softwareschwachstellen vermeiden'
            ),
        },
        {
            title: t(
                'secure_coding_guidelines_title',
                'Secure Coding Guidelines'
            ),
            desc: t(
                'secure_coding_guidelines_desc',
                'Checklisten zur Entwicklung sicherer Software'
            ),
        },
        {
            title: t(
                'netap_review_title',
                'Konfigurationsreview von Netzwerk-Appliances'
            ),
            desc: t(
                'netap_review_desc',
                'Netzwerkkomponenten sicher konfigurieren'
            ),
        },
        {
            title: t('container_security_title', 'Container Security'),
            desc: t(
                'container_security_desc',
                '“DevOps” optimal unterstützen - mit sicheren Container Plattformen'
            ),
        },
        {
            title: t('cloud_security_title', 'Cloud Security'),
            desc: t(
                'cloud_security_desc',
                'Security - in der Cloud erst recht'
            ),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.services}
            headerContent={
                <PageHeaderB
                    title={t('title', 'Secure Engineering')}
                    text={
                        <Trans i18nKey="subtitle">
                            Entwickeln Sie sichere Systeme und implementieren
                            und überwachen Sie entsprechende
                            Sicherheitsmaßnahmen, um die Daten eines
                            Unternehmens vor Cyberangriffen, Verlust oder
                            unbefugtem Zugriff zu schützen.
                        </Trans>
                    }
                />
            }
        >
            <Seo title={t('title', 'Secure Engineering')} />

            <ServiceJumpTable items={text} />

            {/* Secure Coding */}
            <PageSection size="big" id={makeSectionId(text[0].title)}>
                <PageTitle>{text[0].title}</PageTitle>
                <PageSubtitle>{text[0].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="sichere_software_entwicklung_p1">
                        Sichere Software zu entwickeln ist ein komplexes
                        Unterfangen mit vielen Stolpersteinen. Nicht jeder
                        Ratschlag aus den Lehrbüchern eignet sich für jedes
                        Entwicklungsteam gleichermaßen. Gerade “DevOps” und
                        agile Vorgehensweisen haben darüber hinaus nicht nur in
                        der Entwicklung, sondern auch im Betrieb von Anwendungen
                        einen Paradigmenwechsel ausgelöst und
                        Security-Aktivitäten müssen an schnellere
                        Entwicklungs-Zyklen und neue Rollenverteilungen
                        angepasst werden.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="sichere_software_entwicklung_p2">
                        Nutzen Sie unserer Expertise, um in jeder Phase des
                        Entwicklungsprozesses angemessene Security-Aktivitäten
                        zu etablieren. Wir helfen Ihnen bei der Bewertung und
                        beim Aufbau von sicheren Entwicklungsprozessen (Secure
                        Software Development Lifecycle (SSDLC)), der Etablierung
                        von sicheren CI/CD-Pipelines und Tools, sowie der
                        vollumfänglich Absicherung Ihrer “DevOps” Prozesse.
                    </Trans>
                </PageText>
            </PageSection>

            {/* Coding Guidelines */}
            <PageSection size="big" id={makeSectionId(text[1].title)} isDark>
                <PageTitle>{text[1].title}</PageTitle>
                <PageSubtitle>{text[1].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="secure_coding_guidelines_p1">
                        Die meisten Schwachstellen entstehen durch einfache Fehler beim Verfassen von
                        Source Code, die sehr einfach vermieden werden können. Secure Coding Guidelines
                        helfen Ihnen diesen Fehlern und damit Schwachstellen in der Softwareentwicklung
                        vorzubeugen. Entwicklungsteams erhalten damit wertvolles Know-How zur sicheren
                        Entwicklung von Software und können die Guidelines schlussendlich auch als
                        Checkliste verwenden um sicherzustellen, dass alle Sicherheitsmaßnahmen eingehalten
                        wurden.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="secure_coding_guidelines_p2">
                        Condignum erarbeitet mit Ihnen einen individuell zugeschnittenen Secure Coding
                        Guide, welcher effizient Ihre Bedürfnisse abdeckt.
                    </Trans>
                </PageText>
            </PageSection>


            {/* Network Appliances */}
            <PageSection size="big" id={makeSectionId(text[2].title)}>
                <PageTitle>{text[2].title}</PageTitle>
                <PageSubtitle>{text[2].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="netap_review_p1">
                        Um ein produktives Netzwerk zu betreiben sind eine Vielzahl an aktiven
                        Netzwerkkomponenten und -Appliances notwendig, welche die Basis des Netzwerks
                        darstellen. Die Sicherheit dieser Basis ist ausschlaggebend für die Gesamtsicherheit
                        des Netzwerks und der darin betriebenen Anwendungen und Dienste, sowie auch der
                        darin arbeitenden User.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="netap_review_p2">
                        Durch ein Konfigurationsreview im Whitebox-Prinzip werden effizient Schwachstellen 
                        und Abweichungen von Best Practices identifiziert und können anschließend 
                        schnell und einfach behoben werden, um das Sicherheitsniveau Ihres Netzwerks 
                        drastisch zu steigern.
                    </Trans>
                </PageText>
            </PageSection>


            {/* Container Security */}
            <PageSection size="big" id={makeSectionId(text[3].title)} isDark >
                <PageTitle>{text[3].title}</PageTitle>
                <PageSubtitle>{text[3].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="container_security_p1">
                        Agile Entwicklungsmethoden und “DevOps” Prozesse haben
                        das Ziel kleinteilige Artefakte schnell produktiv zu
                        setzen. Container und Container-Plattformen dienen
                        hierbei häufig als Mittel der Wahl zur effizienten
                        Bereitstellung von Services. Doch die flexiblen
                        Plattformen bieten jede Menge an
                        Security-Stolpersteinen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="container_security_p2">
                        Condignum hilft Ihnen diese aus dem Weg zu räumen und
                        Ihre Container Plattform erfolgreich abzusichern.
                    </Trans>
                </PageText>
            </PageSection>

            {/* Cloud */}
            <PageSection size="big" id={makeSectionId(text[4].title)} >
                <PageTitle>{text[4].title}</PageTitle>
                <PageSubtitle>{text[4].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="cloud_security_p1">
                        Die Nutzung von Cloud-Diensten ermöglicht viele neue
                        Chancen und gewinnt dadurch in der IT-Landschaft
                        zunehmend an Bedeutung. Doch mit der Verlagerung von
                        Services und Daten in die Cloud ergeben sich
                        gleichzeitig auch neue Sicherheitsrisiken und rechtliche
                        Verpflichtungen, welche betrachtet werden müssen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="cloud_security_p2">
                        Unsere condignum Security Experten unterstützen Sie bei
                        allen Sicherheitsaspekten ihrer Cloud-Journey. Von der
                        Erarbeitung von Cloud-Security Strategien,
                        Sicherheitsvorgaben, notwendigen Prozessen, sowie der
                        technischen Absicherung und Überprüfung genutzter
                        Clouddienste.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="cloud_security_p3">
                        So können Sie die Vorteile der Cloud genießen, ohne sich
                        den Kopf zu zerbrechen.
                    </Trans>
                </PageText>
            </PageSection>

            <ContactSection requestDemo={false} isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["secure-engineering"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecureEngineering;
